export const peformance = [
  {
    type: 'kpi',
    title: 'WTD Sales',
    md: 3,
    xs: 12,
    options: {},
    qMetrics: [
      {
        qExpr: '=([WTD]/[WTDComparable] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: 'WTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(only(variance)='vs Trend','LW','LY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpi',
    title: 'MTD Sales',
    md: 3,
    xs: 12,
    options: {},
    qMetrics: [
      {
        qExpr: '=([MTD]/[WTDComparable] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: 'MTD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "=if(only(variance)='vs Trend','LM','LY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpi',
    title: 'STD Sales',
    md: 3,
    xs: 12,
    options: {},
    qMetrics: [
      {
        qExpr: 'STD',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: '=([STD]/[STDComparable] - 1)',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: "=if(only(variance)='vs Trend','TY','LY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpi',
    title: 'STD ST',
    md: 3,
    xs: 12,
    options: { kpiPercentage: true },
    qMetrics: [
      {
        qExpr: 'STD/STDDelivered',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: '=(((STD/STDDelivered)) - (([STDComparable]/[STDComparableDelivered])))',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: "=if(only(variance)='vs Trend','TY','LY')",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },

  {
    type: 'line',
    title: 'Sales evolution',
    md: 12,
    xs: 12,
    chartConfig: { categoryLabel: 'weekofyearnumber', metrics: ['Weekly Sales'], isDate: false },
    cols: [
      {
        qField: 'week_id',
        dataKey: 'week_id',
        qLabel: 'week_id',
      },
      {
        qField: '=only(week_dsc)',
        dataKey: 'weekofyearnumber',
        qLabel: 'weekofyearnumber',
      },
      {
        qField: '=WeekOnWeekSales',
        dataKey: 'Weekly Sales',
        qLabel: 'Weekly Sales',
      },
      {
        qField: "=if(YTD<>0,if(metric='Volume',0,1),3)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
    ],
  },

  {
    type: 'table',
    title: 'Sales by Brand',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'STD Mix',
      'delivered_mix',
    ],
    options: {
      coreColumns: ['brand'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: ['WTD Mix', 'vsWTDPP', 'MTD Mix', 'vsMTDPP', 'YTD Mix', 'vsYTDPP', 'STD Mix', 'delivered_mix'],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'STD',
            'ST_mix',
            'Delivered',
            'vs LYYTD',
            'vs LYYTD%',
            'vs LYSTDSTPP',
            'vsSTDComp',
            'vs LYSTD%',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['product_brand'],
        dataKey: 'brand',
        qLabel: 'brand',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LW',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LW',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },

      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LM',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LM',
      },
      {
        qField: '=[STD]',
        dataKey: 'STD',
        qLabel: 'STD',
      },
      {
        qField: '=[STD]-[STDComparable]',
        dataKey: 'vsSTDComp',
        qLabel: 'vs Comp',
      },
      {
        qField: '=([STD]/[STDComparable]) - 1',
        dataKey: 'vs LYSTD%',
        qLabel: 'vs Comp',
      },
      {
        qField: '=[STD]/[STDDelivered]',
        dataKey: 'ST_mix',
        qLabel: 'ST',
      },
      {
        qField: '=(((STD/STDDelivered)) - (([STDComparable]/[STDComparableDelivered])))',
        dataKey: 'vs LYSTDSTPP',
        qLabel: 'vs Comp',
      },

      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='vs Trend','vs Trend ','vs LY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },

      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },

      {
        qField: '=MTDMix',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },

      {
        qField: '=STDMix',
        dataKey: 'STD Mix',
        qLabel: 'STD Mix',
      },

      {
        qField: '=[STDDeliveredMix]',
        dataKey: 'delivered_mix',
        qLabel: 'Delivered Mix',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Gender',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'STD Mix',
      'delivered_mix',
    ],
    options: {
      coreColumns: ['brand'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: ['WTD Mix', 'vsWTDPP', 'MTD Mix', 'vsMTDPP', 'YTD Mix', 'vsYTDPP', 'STD Mix', 'delivered_mix'],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'STD',
            'ST_mix',
            'Delivered',
            'vs LYYTD',
            'vs LYYTD%',
            'vs LYSTDSTPP',
            'vsSTDComp',
            'vs LYSTD%',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['gender'],
        dataKey: 'gender',
        qLabel: 'gender',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LW',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LW',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },

      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LM',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LM',
      },
      {
        qField: '=[STD]',
        dataKey: 'STD',
        qLabel: 'STD',
      },
      {
        qField: '=[STD]-[STDComparable]',
        dataKey: 'vsSTDComp',
        qLabel: 'vs Comp',
      },
      {
        qField: '=([STD]/[STDComparable]) - 1',
        dataKey: 'vs LYSTD%',
        qLabel: 'vs Comp',
      },
      // {
      //   qField: '=[STDDelivered]',
      //   dataKey: 'Delivered',
      //   qLabel: 'Delivered',
      // },
      {
        qField: '=[STD]/[STDDelivered]',
        dataKey: 'ST_mix',
        qLabel: 'ST',
      },
      {
        qField: '=(((STD/STDDelivered)) - (([STDComparable]/[STDComparableDelivered])))',
        dataKey: 'vs LYSTDSTPP',
        qLabel: 'vs Comp',
      },

      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='vs Trend','vs Trend ','vs LY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },

      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },

      {
        qField: '=MTDMix',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },

      {
        qField: '=STDMix',
        dataKey: 'STD Mix',
        qLabel: 'STD Mix',
      },

      {
        qField: '=[STDDeliveredMix]',
        dataKey: 'delivered_mix',
        qLabel: 'Delivered Mix',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Partner',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'STD Mix',
      'delivered_mix',
    ],
    options: {
      coreColumns: ['brand'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: ['WTD Mix', 'vsWTDPP', 'MTD Mix', 'vsMTDPP', 'YTD Mix', 'vsYTDPP', 'STD Mix', 'delivered_mix'],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'STD',
            'ST_mix',
            'Delivered',
            'vs LYYTD',
            'vs LYYTD%',
            'vs LYSTDSTPP',
            'vsSTDComp',
            'vs LYSTD%',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['partner', 'door'],
        dataKey: 'partner',
        qLabel: 'Partner',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LW',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LW',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },

      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LM',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LM',
      },
      {
        qField: '=[STD]',
        dataKey: 'STD',
        qLabel: 'STD',
      },
      {
        qField: '=[STD]-[STDComparable]',
        dataKey: 'vsSTDComp',
        qLabel: 'vs Comp',
      },
      {
        qField: '=([STD]/[STDComparable]) - 1',
        dataKey: 'vs LYSTD%',
        qLabel: 'vs Comp',
      },
      // {
      //   qField: '=[STDDelivered]',
      //   dataKey: 'Delivered',
      //   qLabel: 'Delivered',
      // },
      {
        qField: '=[STD]/[STDDelivered]',
        dataKey: 'ST_mix',
        qLabel: 'ST',
      },
      {
        qField: '=(((STD/STDDelivered)) - (([STDComparable]/[STDComparableDelivered])))',
        dataKey: 'vs LYSTDSTPP',
        qLabel: 'vs Comp',
      },

      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='vs Trend','vs Trend ','vs LY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },

      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },

      {
        qField: '=MTDMix',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },

      {
        qField: '=STDMix',
        dataKey: 'STD Mix',
        qLabel: 'STD Mix',
      },

      {
        qField: '=[STDDeliveredMix]',
        dataKey: 'delivered_mix',
        qLabel: 'Delivered Mix',
      },
    ],
  },
  {
    type: 'table',
    title: 'Sales by Product',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'WTD',
    initialColumnsToExclude: [
      'metric',
      'varianceText',
      'metric',
      'varianceText',
      'WTD Mix',
      'vsWTDPP',
      'MTD Mix',
      'vsMTDPP',
      'YTD Mix',
      'vsYTDPP',
      'STD Mix',
      'delivered_mix',
    ],
    options: {
      coreColumns: ['brand'],
      alwaysHide: ['metric', 'varianceText'],
      dropdown: [
        {
          name: 'Actual',
          hideColumns: ['WTD Mix', 'vsWTDPP', 'MTD Mix', 'vsMTDPP', 'YTD Mix', 'vsYTDPP', 'STD Mix', 'delivered_mix'],
        },
        {
          name: 'Mix',
          hideColumns: [
            'WTD',
            'vs LYWTD',
            'vs LYWTD%',
            'MTD',
            'vs LYMTD',
            'vs LYMTD%',
            'STD',
            'ST_mix',
            'Delivered',
            'vs LYYTD',
            'vs LYYTD%',
            'vs LYSTDSTPP',
            'vsSTDComp',
            'vs LYSTD%',
          ],
        },
      ],
    },
    cols: [
      {
        qField: [
          'function',
          'line',
          "=product_code & 'SPLIT' & Capitalize([product_dsc])  & 'SPLIT' & Capitalize([product_color_dsc]) & 'SPLIT' & [image_url] ",
        ],
        dataKey: 'function',
        qLabel: 'function',
        qGrouping: 'H',
      },
      {
        qField: '=WTD',
        dataKey: 'WTD',
        qLabel: 'WTD',
      },
      {
        qField: '=[WTD]-[WTDComparable]',
        dataKey: 'vs LYWTD',
        qLabel: 'vs LW',
      },
      {
        qField: '=([WTD]/[WTDComparable]) - 1',
        dataKey: 'vs LYWTD%',
        qLabel: 'vs LW',
      },
      {
        qField: '=[MTD]',
        dataKey: 'MTD',
        qLabel: 'MTD',
      },

      {
        qField: '=[MTD]-[MTDComparable]',
        dataKey: 'vs LYMTD',
        qLabel: 'vs LM',
      },
      {
        qField: '=([MTD]/[MTDComparable]) - 1',
        dataKey: 'vs LYMTD%',
        qLabel: 'vs LM',
      },
      {
        qField: '=[STD]',
        dataKey: 'STD',
        qLabel: 'STD',
      },
      {
        qField: '=[STD]-[STDComparable]',
        dataKey: 'vsSTDComp',
        qLabel: 'vs Comp',
      },
      {
        qField: '=([STD]/[STDComparable]) - 1',
        dataKey: 'vs LYSTD%',
        qLabel: 'vs Comp',
      },
      // {
      //   qField: '=[STDDelivered]',
      //   dataKey: 'Delivered',
      //   qLabel: 'Delivered',
      // },
      {
        qField: '=[STD]/[STDDelivered]',
        dataKey: 'ST_mix',
        qLabel: 'ST',
      },
      {
        qField: '=(((STD/STDDelivered)) - (([STDComparable]/[STDComparableDelivered])))',
        dataKey: 'vs LYSTDSTPP',
        qLabel: 'vs Comp',
      },

      {
        qField: "=if(metric='Volume',0,1)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: "=if(variance='vs Trend','vs Trend ','vs LY')",
        dataKey: 'varianceText',
        qLabel: 'varianceText',
      },

      {
        qField: '=WTDMix',
        dataKey: 'WTD Mix',
        qLabel: 'WTD Mix',
      },

      {
        qField: '=MTDMix',
        dataKey: 'MTD Mix',
        qLabel: 'MTD Mix',
      },

      {
        qField: '=STDMix',
        dataKey: 'STD Mix',
        qLabel: 'STD Mix',
      },

      {
        qField: '=[STDDeliveredMix]',
        dataKey: 'delivered_mix',
        qLabel: 'Delivered Mix',
      },
    ],
  },
];

export const filters = [
  {
    title: 'metric',
    dimension: 'metric',
    icon: 'eva:pricetags-fill',
    lock: true,
  },
  {
    title: 'variance',
    dimension: 'variance',
    icon: 'eva:pricetags-fill',
    lock: true,
  },
  {
    title: 'Season',
    dimension: 'season',
    icon: 'eva:calendar-fill',
    lock: true,
  },
  {
    title: 'As Of',
    dimension: 'week',
    icon: 'bx:bxs-calendar-edit',
    singleSelect: true,
  },
  {
    title: 'Brand',
    dimension: 'product_brand',
    icon: 'whh:design',
  },
  {
    title: 'Partner',
    dimension: 'partner',
    icon: 'whh:design',
  },
  {
    title: 'Comparability',
    dimension: 'Comparability',
    icon: 'whh:design',
  },
  {
    title: 'Function',
    dimension: '[function]',
    icon: 'mdi:tshirt-crew',
  },
  {
    title: 'Macro Cat.',
    dimension: '[category]',
    icon: 'mdi:tshirt-crew',
  },
  {
    title: 'Micro Cat.',
    dimension: '[sub_class_dsc]',
    icon: 'mdi:tshirt-crew',
  },
];

export const bestsellers = [
  {
    type: 'bestseller',
    title: 'bestseller',
    qPage: { qWidth: 15, qHeight: 52 },
    sortColumn: 5,
    cols: [
      {
        qField: '[product_code]',
        dataKey: 'ProdCode',
        qLabel: 'ProdCode',
      },
      {
        qField: '=[product_dsc]',
        dataKey: 'ProdDsc',
        qLabel: 'ProdDsc',
      },

      {
        qField: '=[product_color_dsc]',
        dataKey: 'ProdColor',
        qLabel: 'ProdColor',
      },

      {
        qField: '=image_url',
        dataKey: 'URL',
        qLabel: 'URL',
      },

      {
        qField: '[product_brand]',
        dataKey: 'ProdBrand',
        qLabel: 'ProdBrand',
      },
      {
        // eslint-disable-next-line
        qField: '=STD',
        dataKey: 'Sales',
        qLabel: 'Sales',
      },
      {
        // eslint-disable-next-line
        qField: '=sum({$<week_id={"$(current_week_id)"}>} ${value_metric_sales})',
        dataKey: 'SalesValue',
        qLabel: 'SalesValue',
      },
      {
        // eslint-disable-next-line
        qField: '=sum({$<week_id={"$(current_week_id)"}>} qty_sold)',
        dataKey: 'SalesVolume',
        qLabel: 'SalesVolume',
      },
      {
        // eslint-disable-next-line
        qField: '=sum({$<week_id={"$(current_week_id)"}>} qty_delivered)',
        dataKey: 'DeliveredVolume',
        qLabel: 'DeliveredVolume',
      },

      {
        // eslint-disable-next-line
        qField: '=sum({$<week_id={"$(current_week_id)"}>} ${value_metric_delivered})',
        dataKey: 'DeliveredValue',
        qLabel: 'DeliveredValue',
      },
      {
        qField: '=[STD]/[STDDelivered]',
        dataKey: 'STMix',
        qLabel: 'ST',
      },
      {
        qField: "='Rtl ' & Num([rrp_euro],'€###0') & ' WS ' & Num([ws_euro],'€###0')",
        dataKey: 'RRPEuro',
        qLabel: 'RRPEuro',
      },
    ],
  },
];

export const data = [
  {
    type: 'table',
    title: 'Data Accuracy',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'uploadedData',
    disableMetric: true,
    disableVariance: true,
    initialColumnsToExclude: [],
    cols: [
      {
        qField: ['partner'],
        dataKey: 'partner',
        qLabel: 'partner',
        qGrouping: 'H',
      },
      {
        qField: '=uploadedData',
        dataKey: 'uploadedData',
        qLabel: 'last 6 week status',
      },
      {
        qField: '=FirstSortedValue(week_dsc, -Aggr({1} week_id,partner,week_dsc))',
        dataKey: 'most_recent_week',
        qLabel: 'most recent week',
      },
    ],
  },

  {
    type: 'table',
    title: 'Data Accuracy - Deep Dive',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'partner',
    disableMetric: true,
    disableVariance: true,
    initialColumnsToExclude: [],
    cols: [
      {
        qField: 'partner',
        dataKey: 'partner',
        qLabel: 'partner',
      },
      {
        qField: 'week_dsc',
        dataKey: 'week_dsc',
        qLabel: 'week Dsc',
      },
      {
        qField: '=sum(qty_sold)',
        dataKey: 'Sales Qty',
        qLabel: 'Sales Qty',
      },
    ],
  },
];

// {
//   type: 'stackedbar',
//   title: 'Sales Mix',
//   md: 4,
//   xs: 12,
//   chartConfig: { dimension: 'dummy', metrics: { one: 'YTD', two: 'Comparison' } },
//   cols: [
//     {
//       qField: 'Function',
//       dataKey: 'dummy',
//       qLabel: 'dummy',
//     },
//     {
//       qField: '=Round(YTDMix * 100)',
//       dataKey: 'YTD',
//       qLabel: 'YTD',
//     },
//     {
//       qField: '=Round(YTDComparableMix * 100)',
//       dataKey: 'Comparison',
//       qLabel: 'Comparison',
//     },
//     {
//       qField: "=if(YTDMix>0.05,Function,'Other')",
//       dataKey: 'dimension',
//       qLabel: 'dimension',
//     },
//   ],
// },
