import html2canvas from 'html2canvas';

export function exportToPDF(id) {
  const input = document.getElementById(id);

  html2canvas(input, { useCORS: true, allowTaint: true }).then((canvas) => {
    const a = document.createElement('a');
    // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${id}.jpg`;
    a.click();
  });
}
