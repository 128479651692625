import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attributes: { userName: 'Harry Cheslaw', userOrg: 'NGG' },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
});

export default userSlice.reducer;
