import PropTypes from 'prop-types';
import { useVariable } from '@motor-js/engine';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { qLayout } = useVariable({ name: 'user' });

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        {qLayout ? (
          <Avatar src="/" alt={qLayout && qLayout.qText.split(';')[1].split('=')[1].toUpperCase()} />
        ) : (
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        )}

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {qLayout ? qLayout.qText.split(';')[1].split('=')[1].replace('.', ' ') : <Skeleton />}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {qLayout ? qLayout.qText.split(';')[0].split('=')[1] : <Skeleton />}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
