// components
import { useSelections } from '@motor-js/engine';
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';

export default function ClearSelections() {
  const { clearSelections } = useSelections();

  return (
    <IconButtonAnimate sx={{ width: 40, height: 40 }} onClick={() => clearSelections()}>
      <Iconify icon="eva:close-circle-fill" width={20} height={20} />
    </IconButtonAnimate>
  );
}
