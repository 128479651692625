// @mui
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { useTable, useButton } from '@motor-js/engine';
import {
  Box,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Skeleton,
  TableFooter,
  TableSortLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CSVLink } from 'react-csv';

// utils
// _mock
// components
import Scrollbar from '../Scrollbar';
import { fTitleCase } from '../../utils/formatText';
import { fQlikTableCell } from '../../utils/formatQlikTableCell';
import { IconButtonAnimate } from '../animate';
import Iconify from '../Iconify';
import DropDown from './DropDown';
import { exportToPDF } from '../../utils/exportToPDF';

// ----------------------------------------------------------------------

TableQlik.propTypes = {
  title: PropTypes.string.isRequired,
  initialSortKey: PropTypes.string.isRequired,
  initialColumnsToExclude: PropTypes.array,
  cols: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]),
  qPage: PropTypes.object.isRequired,
  disableVariance: PropTypes.bool,
  disableMetric: PropTypes.bool,
  options: PropTypes.object,
};

const useStyles = makeStyles({
  stickyHeader: {
    position: 'sticky',
    left: 0,
    zIndex: '9999999999999',
  },
});

export default function TableQlik({
  title,
  cols,
  qPage,
  initialSortKey,
  initialColumnsToExclude,
  disableVariance,
  disableMetric,
  options,
}) {
  useStyles();
  const [sortColumn, setSortColumn] = React.useState(initialSortKey);
  const [sortDirection, setsortDirection] = React.useState('desc');
  const [csvExportData, setcsvExportData] = React.useState([[]]);

  const [columnsToExclude, setColumnsToExclude] = React.useState(initialColumnsToExclude);

  const { dataSet, headerGroup, select } = useTable({
    cols,
    qPage,
    sortCriteria: { qInterColumnSortOrder: [1] },
  });

  const theme = useTheme();
  const { selectValues } = useButton();

  const handleSelect = (d, dataIndex) => {
    const column = d[dataIndex].columnId;
    const element = d[dataIndex].elemNumber;
    const { text } = d[dataIndex];

    if (text.includes('SPLIT')) {
      const splitValues = text.split('SPLIT');
      selectValues([splitValues[0]], '[product_code]', false);
    } else {
      select(column, [element], false);
    }
  };

  useEffect(() => {
    if (dataSet && headerGroup) {
      const csvHeader = [headerGroup.map((title) => title.title)];
      const dataSetRange = _.range(Object.keys(dataSet[0]).length - 1);

      const csvBodyDataOmit = [...dataSet].map((item) => {
        delete item.key;
        return item;
      });

      const csvBodyData = csvBodyDataOmit.map((obj) =>
        dataSetRange.map((key) => {
          const currentObject = obj[Object.keys(obj)[key]];

          if (currentObject.text === undefined) {
            return '0';
          }

          if (currentObject.columnId === 0) {
            return currentObject.text;
          }

          return currentObject.number;
        })
      );

      setcsvExportData(csvHeader.concat(csvBodyData));
    }
  }, [dataSet, headerGroup]);

  const varianceText =
    !disableVariance &&
    headerGroup &&
    headerGroup.find((element) => element.title === 'varianceText').qGrandTotals.qText;

  const handleOptionSelect = (e) => {
    setColumnsToExclude([...e.target.value.split(','), ...options.alwaysHide]);
  };

  return (
    <Card>
      <CardHeader
        title={dataSet === undefined ? <Skeleton width={200} height={35} /> : title}
        sx={{ mb: 3 }}
        action={
          dataSet === undefined ? (
            <Skeleton width={200} height={35} />
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {options && <DropDown options={options.dropdown} onChange={handleOptionSelect} />}
              <CSVLink data={csvExportData} filename={title}>
                <IconButtonAnimate sx={{ mr: 1, color: theme.palette.grey[500] }}>
                  <Iconify icon="el:download-alt" sx={{ height: 20 }} />
                </IconButtonAnimate>
              </CSVLink>

              <IconButtonAnimate sx={{ mr: 1, color: theme.palette.grey[500] }} onClick={() => exportToPDF(title)}>
                <Iconify icon="el:picture" sx={{ height: 20 }} />
              </IconButtonAnimate>
            </Box>
          )
        }
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720, mb: 3, maxHeight: 300, pl: 1, pr: 1 }}>
          {dataSet === undefined ? (
            <Box sx={{ ml: 2, mr: 2 }}>
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
            </Box>
          ) : (
            <Table stickyHeader id={title}>
              <TableHead>
                <TableRow>
                  {headerGroup.map((d, i) => {
                    const { title, qColumnType, dataIndex } = d;

                    const exclude = columnsToExclude.includes(dataIndex);

                    return (
                      !exclude && (
                        <TableCell
                          key={i}
                          align={qColumnType === 'dim' ? 'left' : 'right'}
                          sx={{
                            padding: columnsToExclude.includes(dataIndex) ? 0 : null,
                          }}
                        >
                          <TableSortLabel
                            active={dataIndex === sortColumn}
                            direction={sortDirection}
                            onClick={() => {
                              setSortColumn(dataIndex);
                              setsortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                            }}
                            sx={{
                              display: columnsToExclude.includes(dataIndex) && 'none',
                            }}
                          >
                            {title.includes('SPLIT')
                              ? 'Product'
                              : title.includes('vs')
                              ? varianceText
                              : fTitleCase(title.replace('[', '').replace(']', ''))}
                          </TableSortLabel>
                        </TableCell>
                      )
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {_.orderBy(
                  dataSet,
                  (item) => (item[sortColumn].number === 'NaN' ? item[sortColumn].text : item[sortColumn].number),
                  [sortDirection]
                ).map((d, i) => (
                  <TableRow key={i}>
                    {headerGroup.map((h, i) => {
                      const { qColumnType, dataIndex } = h;

                      const exclude = columnsToExclude.includes(dataIndex);

                      return (
                        !exclude && (
                          <TableCell
                            key={i}
                            align={qColumnType === 'dim' ? 'left' : 'right'}
                            onClick={() => qColumnType === 'dim' && handleSelect(d, dataIndex)}
                            sx={{
                              cursor: qColumnType === 'dim' && 'pointer',
                              display: columnsToExclude.includes(dataIndex) && 'none',
                            }}
                          >
                            {fQlikTableCell(d[dataIndex], dataIndex, d.metric && !!d.metric.number)}
                          </TableCell>
                        )
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>

              <TableFooter>
                <TableRow>
                  {headerGroup.map((d, i) => {
                    const {
                      qColumnType,
                      dataIndex,
                      qGrandTotals: { qText, qNum },
                    } = d;

                    const exclude = columnsToExclude.includes(dataIndex);

                    return (
                      !exclude && (
                        <TableCell
                          key={i}
                          align={qColumnType === 'dim' ? 'left' : 'right'}
                          sx={{
                            fontSize: columnsToExclude.includes(title) && '0',
                            opacity: columnsToExclude.includes(title) && '0',
                          }}
                        >
                          {i === 0
                            ? 'Total'
                            : fQlikTableCell(
                                { text: qText, number: qNum },
                                dataIndex,
                                !disableMetric &&
                                  !(headerGroup.find((item) => item.dataKey === 'metric').qGrandTotals.qNum === 0)
                              )}
                        </TableCell>
                      )
                    );
                  })}
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
