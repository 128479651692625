import { useState } from 'react';
import { useSearch } from '@motor-js/engine';

// @mui
import { styled } from '@mui/material/styles';
import { Input, Slide, InputAdornment, ClickAwayListener, Fade, Divider, Collapse, List } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
// utils
import cssStyles from '../../utils/cssStyles';
// components
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';
import SearchResult from './SearchResult';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ blur: 8, opacity: 1 }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ListStyled = styled(List)(({ theme }) => ({
  background: 'white',
  boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
  BorderRadius: '10px',
  top: `${APPBAR_MOBILE}px`,
  position: 'absolute',
  color: 'black',
  width: '100%',
  left: 0,
  maxHeight: '150px',
  overflowY: 'scroll',
  padding: 0,
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  [theme.breakpoints.up('md')]: {
    top: `${APPBAR_DESKTOP}px`,
    maxHeight: '200px',
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [searchValue, setSearchValue] = useState('');

  const { groupResults, groupSelect } = useSearch({
    searchValue,
    qCount: 10,
    qGroupItemCount: 10,
  });

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />

            <Fade in={isOpen}>
              <ListStyled sx={{ boxShadow: (theme) => theme.customShadows.z20 }}>
                <TransitionGroup>
                  {groupResults.map((result, i) => (
                    <Collapse key={i} sx={{ cursor: 'pointer' }}>
                      <SearchResult result={result} key={i} groupSelect={groupSelect} />
                      <Divider />
                    </Collapse>
                  ))}
                </TransitionGroup>
              </ListStyled>
            </Fade>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
