import merge from 'lodash/merge';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box, Skeleton } from '@mui/material';
import { useTable } from '@motor-js/engine';

// components
import { BaseOptionChart } from '../chart';

// ----------------------------------------------------------------------

StackedBar.propTypes = {
  title: PropTypes.string.isRequired,
  cols: PropTypes.object,
  chartConfig: PropTypes.object,
};

export default function StackedBar({ title, cols, chartConfig }) {
  const {
    dimension,
    metrics: { one, two },
  } = chartConfig;

  const { dataSet } = useTable({
    cols,
  });

  const chartData = dataSet
    ? _.uniq(_.orderBy(dataSet, (item) => item[one].number, ['desc']).map((item) => item[dimension].text)).map(
        (uniqDimension) => ({
          name: uniqDimension,
          data: dataSet
            .filter((item) => item[dimension].text === uniqDimension)
            .flatMap((object) => [object[one].number, object[two].number]),
        })
      )
    : [];

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      stacked: true,
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
    },
    xaxis: {
      categories: ['YTD', 'LY'],
    },
    yaxis: {
      max: 100,
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        fontSize: '10pt',
      },
      offsetX: 0,
      horizontal: true,
      dropShadow: {
        enabled: false,
      },
    },
    responsive: [
      {
        // This is a 'real' breakpoint
        breakpoint: 960,
        options: {
          // Breakpoint option config
        },
      },
      // Temporary workaround for https://github.com/apexcharts/apexcharts.js/issues/2056
      {
        // 'Fake' breakpoint that will never apply
        breakpoint: 9001,
        options: {}, // You can leave this empty
      },
    ],
  });

  return (
    <Card>
      {dataSet ? (
        <>
          <CardHeader title={title} />

          <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
            <ReactApexChart type="bar" series={chartData} options={chartOptions} height={320} />
          </Box>
        </>
      ) : (
        <Box sx={{ m: 2 }}>
          <Skeleton height={60} />
          <Skeleton height={200} />
        </Box>
      )}
    </Card>
  );
}
