import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
//
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';

// ----------------------------------------------------------------------

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  isCollapse: PropTypes.bool,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemRoot({ item, isCollapse, active }) {
  const { title, path, icon } = item;

  return (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle disableTypography primary={title} isCollapse={isCollapse} />
    </ListItemStyle>
  );
}
