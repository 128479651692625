import mixpanel from 'mixpanel-browser';
import { mixpanelConfig } from '../config/mixpanel';

mixpanel.init(mixpanelConfig.token, { debug: true });

// const envCheck = process.env.NODE_ENV === 'production';

const envCheck = true;

const actions = {
  identify: (id) => {
    if (envCheck) mixpanel.identify(id);
  },
  alias: (id) => {
    if (envCheck) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (envCheck) mixpanel.track(name, props);
  },
  register: (name, props) => {
    if (envCheck) mixpanel.register(name, props);
  },
  people: {
    set: (props) => {
      if (envCheck) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
