// @mui
import { Stack, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useVariable } from '@motor-js/engine';
import { fTitleCase } from '../../../utils/formatText';
// ----------------------------------------------------------------------

export default function NavbarHelp() {
  const theme = useTheme();
  const { qLayout } = useVariable({ name: 'user' });

  return (
    <Stack
      spacing={1}
      sx={{
        px: 5,
        pb: 3,
        pt: 3,
        ml: 3,
        mr: 3,
        mb: 3,
        display: 'block',
        backgroundColor: theme.palette.grey[500_12],
        borderRadius: 1,
      }}
    >
      <Typography gutterBottom variant="subtitle1">
        Hi {qLayout && fTitleCase(qLayout.qText.split(';')[1].split('=')[1].split('.')[0])},
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Need help?
        <br /> Contact us
      </Typography>

      <Button fullWidth variant="contained" href="mailto:support@autone.io">
        Support
      </Button>
    </Stack>
  );
}
