import { useState, useEffect } from 'react';
import { useVariable } from '@motor-js/engine';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Typography, Avatar, Skeleton } from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { Mixpanel } from '../../../utils/mixPanel';
import { mixpanelConfig } from '../../../config/mixpanel';
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [loggedUser, setLoggedUser] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const { qLayout } = useVariable({ name: 'user' });

  useEffect(() => {
    if (qLayout && !loggedUser) {
      setLoggedUser(true);

      const userName = qLayout.qText.split(';')[1].split('=')[1];

      Mixpanel.identify(userName);

      Mixpanel.register({ application: mixpanelConfig.project });

      Mixpanel.track('Successful login');

      Mixpanel.people.set({
        name: userName,
      });
    }
  }, [qLayout]);
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {qLayout ? (
          <Avatar src="/" alt={qLayout && qLayout.qText.split(';')[1].split('=')[1].toUpperCase()} />
        ) : (
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        )}
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {qLayout ? qLayout.qText.split(';')[1].split('=')[1].replace('.', ' ') : <Skeleton />}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {qLayout ? qLayout.qText.split(';')[0].split('=')[1] : <Skeleton />}
          </Typography>
        </Box>
      </MenuPopover>
    </>
  );
}
