import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import moment from 'moment';
import { useState } from 'react';
import { useData } from '@motor-js/engine';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
import { fToNow } from '../../../utils/formatTime';

// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const notifications = [
    {
      id: 'reload_time',
      title: 'app refreshed',
      type: 'refresh',
    },
    {
      id: 'as_of',
      title: 'data as of',
      type: 'asof',
    },
  ];

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const { metrics } = useData({
    qMetrics: [
      {
        qExpr: "=Date(ReloadTime(),'YYYY-MM-DD') & 'T' & Date(ReloadTime(),'HH:mm:ss')",
        qName: 'reload_time',
        qType: 'qStringExpression',
      },
      {
        qExpr: '=only({$<week_id={"$(current_week_id)"}>} week_dsc)',
        qName: 'as_of',
        qType: 'qStringExpression',
      },
      {
        qExpr: '=1',
        qName: 'data_check',
        qType: 'qValueExpression',
      },
    ],
  });

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge variant="dot" color={metrics && !!metrics.data_check ? 'success' : 'error'}>
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Updates
              </ListSubheader>
            }
            sx={{ mb: 2 }}
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} metrics={metrics} />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
  metrics: PropTypes.object,
};

function NotificationItem({ notification, metrics }) {
  const theme = useTheme();
  const value = metrics[notification.id];
  const formattedDate = new Date(value);
  const { id } = notification;

  const { avatar, title } = renderContent(notification, theme, id === 'as_of' ? value : formattedDate, id === 'as_of');

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        bgcolor: 'action.selected',
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {id === 'as_of' ? value : fToNow(formattedDate)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification, theme, formattedDate, week = false) {
  const title = (
    <>
      <Typography variant="subtitle2">{sentenceCase(notification.title)}</Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {week ? formattedDate : moment(formattedDate).format('ddd DD MMM YYYY hh:mm')}
      </Typography>
    </>
  );

  if (notification.type === 'refresh') {
    return {
      avatar: <Iconify icon="eva:sync-fill" width={20} height={20} sx={{ color: theme.palette.primary.main }} />,
      title,
    };
  }

  if (notification.type === 'asof') {
    return {
      avatar: <Iconify icon="eva:pricetags-fill" width={20} height={20} sx={{ color: theme.palette.primary.main }} />,
      title,
    };
  }

  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
