import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fText } from './formatText';
import { fPercentVar, fValueVolume, fPercent, fPercentPointVar } from './formatNumber';
import Label from '../components/Label';

// ----------------------------------------------------------------------

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 74,
  height: 74,
  objectFit: 'cover',
  margin: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadius,
}));

const cover = 'https://d1j9x9sbg3f3rb.cloudfront.net/standard_no_image.jpg';

export function fQlikTableCell({ text, number, columnId }, dataIndex, isMetric) {
  if (dataIndex && dataIndex.includes('uploadedData')) {
    return <Label color={text === 'up to date' ? 'success' : 'error'}>{fText(text)}</Label>;
  }

  if (dataIndex && dataIndex === 'ST_mix') {
    return <Label color={number <= 0.25 ? 'error' : number <= 0.45 ? 'warning' : 'success'}>{fPercent(number)}</Label>;
  }

  if (text && text.includes('SPLIT')) {
    const splitValues = text.split('SPLIT');

    return (
      <>
        <Box
          sx={{
            py: 2,
            pl: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ThumbImgStyle alt="test" src={splitValues[3].includes('ngg-b2b') ? splitValues[3] : cover} />

          <Box
            sx={{
              py: 2,
              display: 'block',
            }}
          >
            <Typography component="div" variant="body2">
              {splitValues[1]}
            </Typography>

            <Typography component="div" variant="body2">
              {splitValues[2]}
            </Typography>

            <Typography component="div" variant="body3" sx={{ color: '#637381' }}>
              {splitValues[0]}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  if (number === 'NaN' || columnId === 0 || (dataIndex && dataIndex.toLowerCase().includes('first sale'))) {
    return fText(text);
  }

  if (dataIndex && dataIndex.toLowerCase().includes('value')) {
    return fValueVolume(number, true);
  }

  if (dataIndex && dataIndex.toLowerCase().includes('mix')) {
    return fPercent(number);
  }

  if (dataIndex && dataIndex.toLowerCase().includes('volume')) {
    return fValueVolume(number, false);
  }

  let displayNumber;

  if (dataIndex && dataIndex.includes('%')) {
    displayNumber = fPercentVar(number);
  } else if (dataIndex && dataIndex.includes('PP')) {
    displayNumber = fPercentPointVar(number);
  } else {
    displayNumber = fValueVolume(number, isMetric);
  }

  if (dataIndex && dataIndex.includes('vs')) {
    return <Label color={number > 0 ? 'success' : 'error'}>{displayNumber}</Label>;
  }

  return displayNumber;
}
