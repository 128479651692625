// deployed

const appIDs = {
  franchise: '85c4502c-fe0c-405f-9b8f-d253caf4a8b5',
};

export default {
  host: '192.168.101.13',
  secure: true,
  port: 19077,
  prefix: 'autone',
  // appId: 'b159d86c-34c5-4d1e-bce1-bd27718d9d3e',
  // the testing app BELOW
  appId: appIDs.franchise,
  // redirectFileName: 'autone_local_direct.html',
  redirectFileName: 'autone_franchise_performance_redirect.html',
  qsServerType: 'onPrem',
};
