import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { TextField } from '@mui/material';

DropDown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function DropDown({ options, onChange }) {
  return (
    <>
      <TextField
        select
        fullWidth
        SelectProps={{ native: true }}
        onChange={(e) => onChange(e)}
        sx={{
          '& fieldset': { border: '0 !important' },
          '& select': {
            pl: 1,
            py: 0.5,
            pr: '24px !important',
            typography: 'subtitle2',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: 0.75,
            bgcolor: 'background.neutral',
          },
          '& .MuiNativeSelect-icon': {
            top: 4,
            right: 0,
            width: 20,
            height: 20,
          },
          mr: 2,
        }}
      >
        {options.map((option) => (
          <option key={option.name} value={option.hideColumns}>
            {option.name}
          </option>
        ))}
      </TextField>
    </>
  );
}
