import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { NavItemRoot } from './NavItem';
import { getActive } from '..';

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  isCollapse: PropTypes.bool,
  list: PropTypes.object,
};

export function NavListRoot({ list, isCollapse }) {
  const { pathname } = useLocation();

  // check if current link is active
  const active = getActive(list.path, pathname);

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}
