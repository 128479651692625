import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Typography, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useData } from '@motor-js/engine';
// utils
import { fPercentVar, fValueVolume, fPercent, fPercentPointVar } from '../../utils/formatNumber';
// components
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------

KPI.propTypes = {
  title: PropTypes.string.isRequired,
  qMetrics: PropTypes.array.isRequired,
  options: PropTypes.object,
};

export default function KPI({ title, qMetrics, options }) {
  const theme = useTheme();

  const { metrics } = useData({
    qMetrics,
  });

  const { hideVariance, kpiPercentage } = options;

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3, height: hideVariance && '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{metrics ? title : <Skeleton />}</Typography>

        <Typography variant="h3" sx={{ mt: 1 }}>
          {metrics ? (
            kpiPercentage ? (
              fPercent(metrics.kpi)
            ) : (
              fValueVolume(metrics.kpi, metrics.metric !== 'Volume')
            )
          ) : (
            <Skeleton />
          )}
        </Typography>

        {!hideVariance && (
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1, mt: 1 }}>
            {metrics ? (
              <IconWrapperStyle
                sx={{
                  ...(metrics.variance < 0 && {
                    color: 'error.main',
                    bgcolor: alpha(theme.palette.error.main, 0.16),
                  }),
                }}
              >
                <Iconify
                  width={16}
                  height={16}
                  icon={metrics.variance >= 0 ? 'eva:trending-up-fill' : 'eva:trending-down-fill'}
                />
              </IconWrapperStyle>
            ) : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            <Typography component="span" variant="subtitle2">
              {metrics ? (
                kpiPercentage ? (
                  fPercentPointVar(metrics.variance)
                ) : (
                  fPercentVar(metrics.variance)
                )
              ) : (
                <Skeleton width={40} />
              )}
              <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                {metrics && ` than ${metrics.varianceText}`}
              </Typography>
            </Typography>
          </Stack>
        )}
      </Box>
    </Card>
  );
}
