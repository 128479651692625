import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useData } from '@motor-js/engine';
import PropTypes from 'prop-types';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Skeleton, Box } from '@mui/material';
// utils
import { fNumber, fPercent, fValueVolume } from '../../utils/formatNumber';
//
import { BaseOptionChart } from '../chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

DonutChart.propTypes = {
  title: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  chartConfig: PropTypes.object.isRequired,
};

export default function DonutChart({ title, cols, chartConfig }) {
  const theme = useTheme();
  const { dimension, metric } = chartConfig;

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.lighter,
      theme.palette.primary.light,
      theme.palette.primary.main,
      theme.palette.primary.dark,
    ],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) =>
          `${fNumber(seriesName)} (${fPercent(
            (seriesName / chartData.data.reduce((partialSum, a) => partialSum + a, 0)) * 100
          )})`,
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            value: {
              formatter: (val) => fNumber(val),
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fValueVolume(sum, dataSet && !!dataSet.data[0].metric);
              },
            },
          },
        },
      },
    },
  });

  const { dataSet } = useData({
    cols,
  });

  const chartData = dataSet.data
    ? { data: dataSet.data.map((item) => item[metric]), labels: dataSet.data.map((item) => item[dimension]) }
    : [];

  return (
    <Card>
      {dataSet.data ? (
        <>
          <CardHeader title={title} />
          <ChartWrapperStyle dir="ltr">
            <ReactApexChart
              type="donut"
              series={chartData.data}
              options={{ ...chartOptions, labels: chartData.labels }}
              height={280}
            />
          </ChartWrapperStyle>
        </>
      ) : (
        <Box sx={{ m: 2 }}>
          <Skeleton height={80} />
          <Skeleton height={160} />
        </Box>
      )}
    </Card>
  );
}
