import PropTypes from 'prop-types';
// @mui
import { Box, Typography } from '@mui/material';

PoweredByAutone.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function PoweredByAutone({ isCollapse }) {
  return (
    <Box sx={{ pb: 3 }}>
      {isCollapse ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box component="img" alt="logo" src="/logo/autone_icon.png" width={30} />
          </Box>
        </>
      ) : (
        <>
          <Typography variant="caption" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            Powered by <Box component="img" alt="logo" src="/logo/autone_icon.png" height={20} sx={{ ml: 1 }} />
          </Typography>
        </>
      )}
    </Box>
  );
}
