// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Motor } from '@motor-js/engine';
import { Provider as ReduxProvider } from 'react-redux';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
//
import App from './App';
import MotorConfig from './config/motor/motor.config';
import store from './redux';
// import motor-js wrapper
// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <SettingsProvider>
      <ReduxProvider store={store}>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <Motor
              config={MotorConfig}
              body={'Please login to access your analytics'}
              buttonColor={'#3366FF'}
              buttonFontColor={'white'}
              header={'Sign in to Autone'}
              licenseKey="U2FsdGVkX19vjmrWbhnS5zEHEGj2PxXnorHHCxZ55lg="
            >
              <App />
            </Motor>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </ReduxProvider>
    </SettingsProvider>
  </HelmetProvider>,
  document.getElementById('root')
);
