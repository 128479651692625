// ----------------------------------------------------------------------

export function fText(text) {
  return text === '-' ? 'nm' : text;
}

export function fTitleCase(str) {
  if (str === undefined) {
    return '';
  }

  if (str === '-') {
    return 'nm';
  }

  if (['WTD', 'MTD', 'QTD', 'YTD', 'vs', 'ST'].some((v) => str.includes(v))) {
    return str;
  }

  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}
